import { ArticleHeader } from '@/components/ArticleHeader';
import { BylineProps } from '@/components/Byline';
import { useAppState } from '@/hooks/useAppState';
import { useStable } from '@/hooks/useStable';
import { LabradorComponent } from '@/types/component';
import { BYLINE_TYPE } from 'base/components/Byline';
import { getB2BVideoInfo, getVideoOptions } from 'base/components/JwVideo/Article/utils/dataParser';
import { findAllOfType } from 'lib/labrador/content-operations/findBy';

export const ElleLabradorArticleHeader: LabradorComponent = ({ data, descendants }) => {
  const [{ device }] = useAppState();

  const { format = 'DD MMM, YYYY', publishedDatetime, updatedDatetime } = data ?? {};

  const labradorImage = descendants?.find((descendant) => descendant.type === 'labradorImage')?.data;

  const headerVideo = descendants?.find(({ type }) => type === 'jwVideo')?.data;

  const videoProps =
    headerVideo && !labradorImage
      ? {
          videoData: { ...getVideoOptions(headerVideo), title: '' },
          showDescription: headerVideo.enableDesc,
          b2bVideo: getB2BVideoInfo(headerVideo),
          playerSrc: headerVideo.playerSrc,
          videoPageLink: headerVideo.teaserLink,
        }
      : undefined;

  const { imageUrl, alt, altText, byline } = labradorImage ?? {};

  const src = imageUrl?.[device];

  const bylines: BylineProps[] = findAllOfType(descendants, 'byline').map(({ data }) => ({
    type: BYLINE_TYPE.AUTHOR,
    author: [data.firstName, data.lastName].filter(Boolean).join(' '),
    authorPageSlug: data.authorPageSlug,
  }));

  if (data.photoByline) {
    bylines.push({
      type: BYLINE_TYPE.PHOTOGRAPHER,
      author: data.photoByline,
      authorPageSlug: data.authorPageSlug,
    });
  }

  if (data.extraBylineContent) {
    bylines.push({
      type: BYLINE_TYPE.FREE,
      author: data.extraBylineContent,
      text: data.extraBylineLabel,
      authorPageSlug: data.authorPageSlug,
    });
  }

  const stableProps = useStable({
    category: { value: data.category, url: data.categoryUrl },
    bylines,
    image: { src, alt: alt || altText, byline },
    datetime: { publishedDatetime, updatedDatetime, format },
    tags: data.tags?.map((tag: string) => ({
      value: tag,
    })),
    video: videoProps,
  });

  return <ArticleHeader headline={data.title} description={data.subtitle} {...stableProps} />;
};
