import { SliderSlide, type SliderSlideProps } from '@/components/Slider/Slide';
import { SliderSwiper, type SliderSwiperProps } from '@/components/Slider/Swiper';
import { useAppState } from '@/hooks/useAppState';
import { componentTheme } from '@/styles/AffiliateGallery';
import type { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import { withTrackingAttributes } from '@/utils/withTrackingAttributes';
import Tracking from 'base/components/TrackingComponent';
import { useMemo } from 'react';
import { AffiliateGallery, AffiliateGallerySubcomponentProps } from './AffiliateGallery';

SliderSlide.displayName = 'SwiperSlide'; // Required for `Swiper` to work

export interface AffiliateGalleryProduct {
  id: number;
  link: string;
  image_link: string;
  price: string;
  title: string;
}
interface ExtendedOptions {
  $slide?: SliderSlideProps['options'];
  $swiper?: SliderSwiperProps['options'];
  $line?: AffiliateGallerySubcomponentProps['Disclaimer']['Line'];
}

export interface StandaloneAffiliateGalleryProps
  extends StandaloneComponentProps<typeof AffiliateGallery, ExtendedOptions> {
  disclaimer?: React.ReactNode;
  title?: string;
  products?: AffiliateGalleryProduct[];
}

export const StandaloneAffiliateGallery: StandaloneComponent<StandaloneAffiliateGalleryProps> = ({
  disclaimer,
  title,
  products,
  options,
  ...props
}) => {
  const [{ type }] = useAppState();

  const { $swiper, $slide, $title, $disclaimer, $line, $price, ...$base } = { ...options };

  const ctsAttrs = withTrackingAttributes({
    category: 'affiliate-carousel',
    name: title,
    extraInfo: [{ type: 'carousel' }],
  });

  const memoSlides = useMemo(
    () =>
      products?.map(({ id, link, image_link, price, title }, index) => (
        <SliderSlide
          key={id}
          url={link}
          title={
            <>
              <AffiliateGallery.Title {...$title}>{title}</AffiliateGallery.Title>
              <AffiliateGallery.Price {...$price}>{price}</AffiliateGallery.Price>
            </>
          }
          imageSrc={image_link}
          options={mergeOptions({ className: 'cts-tracking-object', theme: componentTheme('slide') }, $slide)}
          {...withTrackingAttributes({
            category: 'affiliate-carousel',
            name: title,
            url: link,
            extraInfo: [{ type: 'product' }],
            placement: `${index + 1}`,
          })}
        />
      )),
    [$price, $slide, $title, products],
  );

  return (
    <Tracking options={{ wrapChildren: true, ctsAttrs }}>
      <AffiliateGallery {...$base} {...props}>
        <AffiliateGallery.Disclaimer.Line {...$line}>
          <AffiliateGallery.Disclaimer {...$disclaimer}>{disclaimer}</AffiliateGallery.Disclaimer>
        </AffiliateGallery.Disclaimer.Line>
        <SliderSwiper
          content={memoSlides}
          title={title}
          options={mergeOptions({ theme: componentTheme('swiper') }, $swiper)}
        />
      </AffiliateGallery>
    </Tracking>
  );
};
